(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentPickerController', ContentPickerController);

    /* @ngInject */
    function ContentPickerController($scope, $state, triSettings, $mdDialog, Content, Feature, Folder, ParseLinks, AlertService, ContentAction, $mdToast, $filter, CONTENT_META, filter, isWeb, isNotFilter, allowMultiSelect, paginationConstants) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.loadingData = false;
        vm.pageTitleKey = $filter('translate')('autoComplete.contentPicker.content');
        vm.predicate = "id";
        vm.transition = transition;
        vm.reOrder = reOrder;
        vm.showFolders = false;
        vm.selected = [];
        vm.allowMultiSelect = allowMultiSelect;
        vm.isWeb = isWeb;
        vm.isNotFilter = isNotFilter;
        $scope.secondaryColor = triSettings.getSkinModel().secondary_color_hex;

        vm.currentSelectedCards = [];

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;
        vm.getQueryString = getQueryString;

        vm.defaultFolder = {
            id: "default",
            name: "Default",
            description: "Default"
        };

        vm.selectedFolder = vm.defaultFolder;


        function loadAll() {
            vm.loadingData = true;
            Content.query({
                page: 0,
                size: vm.query.limit,
                sort: sort(),
                query: getQueryString(),
                is_web: vm.isWeb,
                is_not_filter: vm.isNotFilter
            }, onSuccess, onError);

            function sort() {
                var result = ['id' + ',' + 'asc'];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contents = data;
                vm.page = 1;
                vm.loadingData = false;
            }

            function onError(error) {
                vm.loadingData = false;
                AlertService.error(error.data.message);
            }
        }

        vm.getMediaUrl = function(mediaUrl){
            return $filter('mediaUrl')(mediaUrl);
        }

        vm.selectFolder = function(folder) {
            vm.updateObject = false;
            vm.selectedFolder = folder;
            vm.loadAll();
        }

        vm.getCardProperty = function(type) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: type.replace(/_/g, '-')
            })[0];
            return contentMetaData.card_properties;
        }

        vm.getDefaultBackground = function(type) {
            var card_properties = vm.getCardProperty(type);
            return card_properties != undefined ? card_properties.defaultBackground : '';
        }

        vm.getDefaultIcon = function(type) {
            var card_properties = vm.getCardProperty(type);
            return card_properties != undefined ? card_properties.defaultIcon : '';
        }

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
            if (vm.filter.show) {
                vm.showFolders = false;
            }
        }

        vm.updateParentObject = function() {
            $mdDialog.hide(vm.currentSelectedCards);
        }

        vm.getCardMeta = function(card) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: card.data.type.replace(/_/g, '-')
            })[0];
            return contentMetaData;
        }

        vm.close = function() {
            $mdDialog.cancel();
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        }

        function transition() {
            vm.promise = Content.query({
                page: vm.page - 1,
                size: vm.query.limit,
                sort: getSortOrder(),
                query: getQueryString(),
                is_web: vm.isWeb,
                is_not_filter: vm.isNotFilter
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.contents = data;
            });
        }

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }

        function getQueryString() {
            var contentQuery = filter;
            if (vm.query.filter.length <= 0) {
                contentQuery = contentQuery + ';folders=ex=false,folders=size=0;enabled==true';
            } else {
                var quoteEscapedStr = String(vm.query.filter).replace(/([\\"])/g, '\\$1');
                return contentQuery + ';(title=re="' + quoteEscapedStr + '",description=re="' + quoteEscapedStr + '",tags=re="' + quoteEscapedStr + '");enabled==true';
            }

            return contentQuery;
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            resetModal();
        }

        function resetModal() {
            loadAll();
            vm.showFolders = false;
            AlertService.displayToast($mdToast);
        };
        vm.loadAll();
    }
})();
